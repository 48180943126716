<template>
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <router-link :to="'/addresses/edit/' + id" class="nav-link" :title="t('general.edit')">{{
        t("address.address")
      }}</router-link>
    </li>
    <li class="nav-item">
      <router-link :to="'/addresses/addresscontacts/' + id" class="nav-link" :title="t('general.show')">{{
        t("nav.addresscontacts")
      }}</router-link>
    </li>
    <li class="nav-item">
      <router-link :to="'/addresses/contactof/' + id" class="nav-link" :title="t('general.show')">{{
        t("nav.contactof")
      }}</router-link>
    </li>
    <li v-for="(nav, index) in additionalNavigation" :key="index" class="nav-item">
      <router-link v-if="nav.url.length > 0" :to="nav.url + id" class="nav-link" :title="nav.title">{{
        nav.text
      }}</router-link>
      <a v-else class="nav-link">{{ nav.text }}</a>
    </li>
  </ul>
</template>

<script>
import { useI18n } from "vue-i18n/index";
import { computed } from "@vue/runtime-core";
export default {
  props: ["id", "categories"],
  setup(props) {
    const { t } = useI18n();
    const tabs = [
      {
        name: "Plattenfirma",
        links: [
          {
            url: "",
            title: t("general.show"),
            text: "Platten der Firma",
          },
        ],
      },
      {
        name: "Veranstalter",
        links: [
          // {
          //   url: "",
          //   title: t("general.show"),
          //   text: "Festivals",
          // },
          {
            url: "/addresses/jobs/",
            title: t("general.show"),
            text: t("nav.jobs"),
          },
        ],
      },
      {
        name: "Festival",
        links: [
          {
            url: "/addresses/festivaldata/",
            title: t("general.show"),
            text: "Festival Daten",
          },
        ],
      },
      {
        name: "Medien",
        links: [],
      },
      {
        name: "Künstler",
        links: [
          {
            url: "/addresses/gigs/",
            title: t("general.show"),
            text: t("nav.gigs"),
          },
          {
            url: "/addresses/jobs/",
            title: t("general.show"),
            text: t("nav.jobs"),
          },
          // {
          //   url: "/addresses/tours/",
          //   title: t("general.show"),
          //   text: t("nav.tours"),
          // },
          {
            url: "/addresses/availabilities/",
            title: t("general.show"),
            text: t("nav.availabilities"),
          },
        ],
      },
      {
        name: "Venue",
        links: [
          {
            url: "/addresses/stages/",
            title: t("general.show"),
            text: "Bühnen",
          },
          {
            url: "/addresses/gigsvenue/",
            title: t("general.show"),
            text: t("nav.gigs"),
          },
        ],
      },
      {
        name: "Sponsor",
        links: [],
      },
      {
        name: "Unterkunft",
        links: [
          {
            url: "",
            title: t("general.show"),
            text: "Hotel",
          },
        ],
      },
      {
        name: "Technik",
        links: [],
      },
    ];

    const additionalNavigation = computed(() => {
      let links = [];
      tabs.forEach((tab) => {
        if (Array.isArray(props.categories)) {
          props.categories.forEach((category) => {
            if (category[tab.name] && category[tab.name] === 1) {
              // push tab.links to links if not already in links
              tab.links.forEach((link) => {
                if (!links.some((l) => l.url === link.url)) {
                  links.push(link);
                }
              });
            }
          });
        }
      });
      return links;
    });

    return {
      t,
      tabs,
      additionalNavigation,
    };
  },
};
</script>
