<template>
  <div class="gws_content_container p-3">
    <div class="gws_content_container p-3">
      <!-- title -->
      <div class="row g-3">
        <div class="col-md-12">
          <h1 class="text-center mb-0">{{ t("nav.availabilities") }}</h1>
          <div class="subtitle-name mb-3 text-center">{{ displayName }}</div>
        </div>
      </div>
      <!-- nav -->
      <div class="row g-3">
        <div class="col-md-12">
          <AddressNavigation :categories="address.categories" :id="$route.params.id"></AddressNavigation>
        </div>
      </div>
      <!-- add new -->
      <div class="gws_control_bar d-lg-flex justify-content-center align-items-center p-2 mt-3 mb-2">
        <button
          @click="addModal = !addModal"
          type="button"
          class="d-flex flex-row align-items-center justify-content-center btn btn-success p-0 px-2 me-2"
          :title="t('general.add')"
        >
          <svg class="" width="32" height="32" fill="currentColor">
            <use xlink:href="/assets/img/bootstrap-icons.svg#plus"></use>
          </svg>
          <div>{{ t("general.add") }}</div>
        </button>
      </div>
      <!-- edit -->
      <div class="row" v-for="availability in address.availabilities" :key="availability.id">
        <div class="col">
          <div class="card my-2">
            <div class="card-body">
              <div class="card-text">
                <div class="row g-3">
                  <div class="col-lg-2 col-md-4">
                    <label :for="'availability_from_' + availability.id" class="form-label">{{
                      t("availability.from")
                    }}</label>
                    <input
                      v-model="availability.from"
                      type="date"
                      class="form-control"
                      :id="'availability_from_' + availability.id"
                    />
                  </div>
                  <div class="col-lg-2 col-md-4">
                    <label :for="'availability_to_' + availability.id" class="form-label">{{
                      t("availability.to")
                    }}</label>
                    <input
                      v-model="availability.to"
                      type="date"
                      class="form-control"
                      :id="'availability_to_' + availability.id"
                    />
                  </div>
                  <div class="col-lg-2 col-md-4">
                    <label :for="'availability_type_' + availability.id" class="form-label">{{
                      t("availability.type")
                    }}</label>
                    <select
                      v-model="availability.type"
                      class="form-select"
                      :id="'availability_type_' + availability.id"
                    >
                      <option value="available">{{ t("availability.available") }}</option>
                      <option value="unavailable">{{ t("availability.unavailable") }}</option>
                      <option value="tourperiod">{{ t("availability.tourperiod") }}</option>
                    </select>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <label :for="'availability_comment_' + availability.id" class="form-label">{{
                      t("general.comment")
                    }}</label>
                    <textarea
                      v-model="availability.comment"
                      class="form-control fz-xs"
                      :id="'availability_comment_' + availability.id"
                    />
                  </div>
                </div>
                <!-- controls -->
                <div class="row">
                  <div class="col-12 border-top mt-3 pt-3 d-flex justify-content-center align-items-center">
                    <button
                      @click="saveAvailability(availability)"
                      type="button"
                      class="btn btn-success btn-icon me-2"
                      :title="t('general.save')"
                    >
                      <svg class="bi" width="20" height="20" fill="currentColor">
                        <use xlink:href="/assets/img/bootstrap-icons.svg#check"></use>
                      </svg>
                      {{ t("general.save") }}
                    </button>
                    <button
                      @click="toggleDeleteModal(availability.id)"
                      type="button"
                      class="btn btn-danger btn-icon me-2"
                      :title="t('general.delete')"
                    >
                      <svg class="bi" width="20" height="20" fill="currentColor">
                        <use xlink:href="/assets/img/bootstrap-icons.svg#x"></use>
                      </svg>
                      {{ t("general.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal @close="toggleDeleteModal" :modalActive="delteActiveModal">
    <template v-slot:header>{{ t("availability.delete") }}</template>
    <template v-slot:body>
      <p>{{ t("availability.delete_confirm_inquire") }}</p>
    </template>
    <template v-slot:footer>
      <button @click="toggleDeleteModal" type="button" class="btn btn-secondary">{{ t("general.cancel") }}</button>
      <button @click="deleteAvailability" type="button" class="btn btn-danger">{{ t("general.remove") }}</button>
    </template>
  </Modal>
  <Modal @close="addModal = !addModal" :modalActive="addModal" modalCssClass="modal-xl">
    <template v-slot:header>{{ t("availability.add") }}</template>
    <template v-slot:body>
      <div class="container-fluid">
        <div class="row g-3">
          <div class="col-sm-6">
            <label for="availability_from_new" class="form-label">{{ t("availability.from") }}</label>
            <input v-model="newAvailability.from" type="date" class="form-control" id="availability_from_new" />
          </div>
          <div class="col-sm-6">
            <label for="availability_to_new" class="form-label">{{ t("availability.to") }}</label>
            <input v-model="newAvailability.to" type="date" class="form-control" id="availability_to_new" />
          </div>
          <div class="col-sm-6">
            <label for="availability_type_new" class="form-label">{{ t("availability.type") }}</label>
            <select v-model="newAvailability.type" class="form-select" id="availability_type_new">
              <option value="available">{{ t("availability.available") }}</option>
              <option value="unavailable">{{ t("availability.unavailable") }}</option>
              <option value="tourperiod">{{ t("availability.tourperiod") }}</option>
            </select>
          </div>
          <div class="col-sm-6">
            <label for="availability_comment_new" class="form-label">{{ t("general.comment") }}</label>
            <textarea v-model="newAvailability.comment" class="form-control" id="availability_comment_new" />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button @click="addModal = !addModal" type="button" class="btn btn-secondary">{{ t("general.cancel") }}</button>
      <button @click="createAvailability" type="button" class="btn btn-success">{{ t("general.save") }}</button>
    </template>
  </Modal>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import { useApiQuery } from "@/composables/useApiQueries";
import { useToast } from "vue-toastification";
import AddressNavigation from "@/components/navigation/Address.vue";
import Modal from "@/components/Modal.vue";
import { getDisplayName } from "@/composables/utilities";
const store = useStore();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const toast = useToast();
const currentUser = computed(() => store.getters.user);

// get address with availabilities
const { result: address, query: getAddress } = useApiQuery({
  url: "addresses/" + route.params.id,
  params: {
    params: {
      withAvailabilities: true,
    },
  },
});

// create availability
const { query: postAvailabilityQuery, error: postError } = useApiQuery({
  method: "post",
});

// update availability
const { query: putAvailabilityQuery, error: putError } = useApiQuery({
  method: "put",
});

// delete availability
const { query: delAvailabilityQuery, error: delError } = useApiQuery({
  method: "delete",
});

const addModal = ref(false);
const newAvailability = ref({
  from: "",
  to: "",
  type: "",
  comment: "",
});
const createAvailability = async () => {
  const availability = {
    address_id: route.params.id,
    created_by: currentUser.value.id,
    updated_by: currentUser.value.id,
  };
  Object.assign(availability, newAvailability.value);
  addModal.value = false;
  try {
    await postAvailabilityQuery({
      url: "availability",
      params: availability,
    });
    if (postError.value) {
      toast.error(t("error.errorOccured") + " " + postError.value.response.status);
    } else {
      toast.success(t("general.availability") + " " + t("general.successfully") + " " + t("general.added") + ".");
      await getAddress();
    }
  } catch (error) {
    if (error.response && error.response.status == 401) {
      store.dispatch("logout");
      router.push({ name: "Login" });
    } else {
      toast.error(t("error.errorOccured") + " " + error.response.status);
    }
  }
};

const deleteAvailability = async () => {
  if (!selectedAvailabilityId.value) {
    return;
  }
  const id = selectedAvailabilityId.value;
  try {
    toggleDeleteModal();
    await delAvailabilityQuery({
      url: "availability/" + id,
    });
    if (delError.value) {
      toast.error(t("error.errorOccured") + " " + delError.value.response.status);
    } else {
      toast.success(t("general.availability") + " " + t("general.successfully") + " " + t("general.deleted") + ".");
    }
    await getAddress();
  } catch (error) {
    if (error.response && error.response.status == 401) {
      store.dispatch("logout");
      router.push({ name: "Login" });
    } else {
      toast.error(t("error.errorOccured") + " " + error.response.status);
    }
  }
};

const saveAvailability = async (availability) => {
  availability.updated_by = currentUser.value.id;
  delete availability.created_by;
  delete availability.created_at;
  delete availability.updated_at;
  try {
    await putAvailabilityQuery({
      url: "availability/" + availability.id,
      params: availability,
    });
    if (putError.value) {
      toast.error(t("error.errorOccured") + " " + putError.value.response.status);
    } else {
      toast.success(t("general.availability") + " " + t("general.successfully") + " " + t("general.edited") + ".");
    }
  } catch (error) {
    if (error.response && error.response.status == 401) {
      store.dispatch("logout");
      router.push({ name: "Login" });
    } else {
      toast.error(t("error.errorOccured") + " " + error.response.status);
    }
  }
};

const selectedAvailabilityId = ref(null);
const delteActiveModal = ref(false);
const toggleDeleteModal = (id) => {
  if (id) {
    selectedAvailabilityId.value = id;
  } else {
    selectedAvailabilityId.value = null;
  }
  delteActiveModal.value = !delteActiveModal.value;
};

const displayName = computed(() => {
  return getDisplayName(address.value);
});
async function getAll() {
  await Promise.all([getAddress()]);
}
getAll();
</script>
