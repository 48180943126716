// import { useRouter } from "vue-router";
// import { useStore } from "vuex";
// import { useToast } from "vue-toastification";

export function getContrastColor(hexcolor, threshold) {
  if (threshold === undefined) {
    threshold = 128;
  }
  hexcolor = hexcolor.replace("#", "");
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= threshold ? "#000" : "#fff";
}

export function getDisplayName(address) {
  if (address.name) {
    return address.name;
  } else if (address.forename && address.surname) {
    return address.forename + " " + address.surname;
  } else if (address.forename) {
    return address.forename;
  } else if (address.surname) {
    return address.surname;
  } else {
    return "";
  }
}

export function nl2br(str) {
  return !str ? "" : str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1<br>$2");
}

export function localDateTime(date, local) {
  return new Date(date).toLocaleString(local);
}

// export function handleErrors(error) {
//   const store = useStore();
//   const router = useRouter();
//   const toast = useToast();
//   if (error.response && error.response.status == 401) {
//     store.dispatch("logout");
//     router.push({ name: "Login" });
//   } else {
//     toast.error("Es ist ein Fehler aufgetreten." + " " + error.response.status);
//   }
// }
